<template>
  <ftx-dialog
    dialog="LNSCouponsDialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="false"
  >
    <template v-slot:DialogContent>
      <q-card class="couponDialogue">
        <div class="dialogHeader text-center q-py-md q-px-lg">
          <Logo />
          <q-btn
            class="close float-right q-pa-md absolute-top-right"
            flat
            dense
            size="6px"
            v-close-popup
          >
            <SvgIcon icon="close" />
          </q-btn>
        </div>
        <q-separator />
        <q-card-section class="">
          <q-scroll-area
            class="coupon-card-container"
            :thumb-style="scrollAreaThumbStyle"
            :bar-style="scrollAreaBarStyle"
            :visible="scrollAreaVisible"
            :style="
              isMobile
                ? 'height: calc(100dvh - 310px);'
                : 'height: calc(80dvh - 266px);'
            "
          >
            <div
              class="row q-col-gutter-md q-pa-md"
              v-show="properties.loading"
            >
              <div class="col-12 col-sm-6 col-md-4" v-for="n in 3" :key="n">
                <strong><q-skeleton type="rect" height="200px" /></strong>
              </div>
            </div>
            <div
              class="row q-col-gutter-md q-pr-md"
              v-show="!properties.loading"
            >
              <template
                v-if="
                  properties.coupons &&
                  Object.entries(properties.coupons).length
                "
              >
                <div
                  class="col-12 col-sm-6 col-md-4"
                  v-for="coupon in properties.coupons"
                  :key="coupon.id"
                >
                  <div
                    :class="[
                      'coupon-card-item',
                      {
                        'coupon-card-active': selectedCoupons.includes(coupon),
                      },
                    ]"
                    @click="couponSelection(coupon)"
                  >
                    <q-img :src="coupon.image" style="max-height: 210px" />
                    <!-- des: [ 'cover', 'fill', 'contain', 'none', 'scale-down' ] -->
                  </div>
                </div>
              </template>
              <div class="col-12 text-center pa-lg" v-else>
                Coupons not available
              </div>
            </div>
          </q-scroll-area>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right" class="text-primary q-px-md q-py-md">
          <!-- <q-btn label="Cancel" /> -->
          <q-btn
            unelevated
            label="Redeem"
            color="secondary"
            padding="8px 24px"
            @click="redeemCoupons"
            :disable="!selectedCoupons.length"
          />
        </q-card-actions>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import Logo from 'src/components/header/Logo.vue'

export default {
  data() {
    return {
      selectedCoupons: [],
    }
  },
  components: {
    Logo,
  },
  computed: {
    // ...mapGetters('customer', ['coupons']),
    properties() {
      return this.getDialogProperties('LNSCouponsDialog')
    },
  },
  methods: {
    couponSelection(coupon) {
      let index = this.selectedCoupons.indexOf(coupon)
      if (index > -1) this.selectedCoupons.splice(index, 1)
      else this.selectedCoupons.push(coupon)
    },
    async redeemCoupons() {
      await this.properties.applyCoupons(this.selectedCoupons)
      this.selectedCoupons = []
    },
  },
}
</script>
<style lang="scss">
body .couponDialogue {
  max-width: 100% !important;
  width: 100% !important;
  overflow: hidden;
  @media (min-width: 1024px) {
    max-width: 1000px !important;
    border-radius: 10px !important;
  }
}
.coupon-card-container {
  .coupon-card-item {
    border: 2px solid #cdcdcd;
    border-radius: 7px;
    padding: 8px;
    display: flex;
    min-height: 230px;
    transition: 0.2s ease all;

    .q-img__image {
      background-size: contain !important;
    }
    &.coupon-card-item.coupon-card-active {
      border-color: $primary;
    }
  }
}
</style>
