var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ftx-dialog',{attrs:{"dialog":"LNSCouponsDialog","position":_vm.isMobile ? 'bottom' : 'standard',"maximized":false},scopedSlots:_vm._u([{key:"DialogContent",fn:function(){return [_c('q-card',{staticClass:"couponDialogue"},[_c('div',{staticClass:"dialogHeader text-center q-py-md q-px-lg"},[_c('Logo'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"close float-right q-pa-md absolute-top-right",attrs:{"flat":"","dense":"","size":"6px"}},[_c('SvgIcon',{attrs:{"icon":"close"}})],1)],1),_c('q-separator'),_c('q-card-section',{},[_c('q-scroll-area',{staticClass:"coupon-card-container",style:(_vm.isMobile
              ? 'height: calc(100dvh - 310px);'
              : 'height: calc(80dvh - 266px);'),attrs:{"thumb-style":_vm.scrollAreaThumbStyle,"bar-style":_vm.scrollAreaBarStyle,"visible":_vm.scrollAreaVisible}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.properties.loading),expression:"properties.loading"}],staticClass:"row q-col-gutter-md q-pa-md"},_vm._l((3),function(n){return _c('div',{key:n,staticClass:"col-12 col-sm-6 col-md-4"},[_c('strong',[_c('q-skeleton',{attrs:{"type":"rect","height":"200px"}})],1)])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.properties.loading),expression:"!properties.loading"}],staticClass:"row q-col-gutter-md q-pr-md"},[(
                _vm.properties.coupons &&
                Object.entries(_vm.properties.coupons).length
              )?_vm._l((_vm.properties.coupons),function(coupon){return _c('div',{key:coupon.id,staticClass:"col-12 col-sm-6 col-md-4"},[_c('div',{class:[
                    'coupon-card-item',
                    {
                      'coupon-card-active': _vm.selectedCoupons.includes(coupon),
                    },
                  ],on:{"click":function($event){return _vm.couponSelection(coupon)}}},[_c('q-img',{staticStyle:{"max-height":"210px"},attrs:{"src":coupon.image}})],1)])}):_c('div',{staticClass:"col-12 text-center pa-lg"},[_vm._v("\n              Coupons not available\n            ")])],2)])],1),_c('q-separator'),_c('q-card-actions',{staticClass:"text-primary q-px-md q-py-md",attrs:{"align":"right"}},[_c('q-btn',{attrs:{"unelevated":"","label":"Redeem","color":"secondary","padding":"8px 24px","disable":!_vm.selectedCoupons.length},on:{"click":_vm.redeemCoupons}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }